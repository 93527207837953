@import '~yordex-ui-kit/yordexUiKit';
@import '../../scss/form';

.forgot-password {
  &__link {
    @extend %link;
  }

  &__paragraph {
    @include display-as($font-type-t300);
    
    margin: 0;
  }

  &__subtitle {
    margin-bottom: spacing(2);
  }
}
