@import '~yordex-ui-kit/yordexUiKit';

.public-layout {
  $width: spacing(59);
  align-items: center;
  background-color: $neutral-0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  &__logo-container {
    margin: spacing(3) spacing(1);
    max-width: 505px;
    position: relative;
    width: 90%;

    @include breakpoint(xs) {
      margin: spacing(5) 0 spacing(6);
    }
  }

  &__yordex-logo,
  &__ebury-logo {
    left: spacing(4);
    position: relative;
  }

  &__container {
    align-items: flex-start;
    background-color: $white;
    border: 1px solid $neutral-20;
    border-radius: 4px;
    box-shadow: $generic-box-shadow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: $width;
    padding: spacing(2);
    position: relative;
    width: 90%;

    @include breakpoint(xs) {
      padding: spacing(4);
    }
  }

  &__global-error {
    margin-bottom: spacing(3);
    max-width: $width;
    width: 90%;
  }

  &__title {
    @include display-as($font-type-t700);

    margin-bottom: spacing(1);
  }

  &__subtitle {
    @include display-as($font-type-t300);

    margin-bottom: spacing(3);
    margin-top: 0;
  }

  &__footer-links {
    @include display-as($font-type-t300);
    color: $neutral-70;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: spacing(5);
    padding: spacing(2);
    text-align: center;

    @include breakpoint(small) {
      align-items: flex-end;
      flex-direction: row;
    }
  }

  &__link {
    @include display-as($font-type-t300);
    color: $neutral-70;
    margin-left: spacing(0.5);

    &:hover {
      color: $neutral-90;
    }
  }

  &__dot {
    margin-left: spacing(0.5);
  }

  &__languageSelector {
    margin-top: 0;
    @include breakpoint(xs) {
      position: absolute;
      right: 3%;
      top: 0;
    }
  }
}
