@import '~yordex-ui-kit/yordexUiKit';

.trader-selector {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  
  &--open {
    width: 100%;
  }
  
  &__button {
    @include display-as($font-type-t350);
    align-items: center;
    background-color: $neutral-15;
    border: unset;
    color: $neutral-80;
    display: inline-flex;
    justify-content: space-around;
    padding: spacing();
    width: spacing(20.5);
      
    &:enabled {
      &:hover,
      &:focus {
        border: unset !important;
      }
    }
  
    &--focused {
      box-shadow: 0 0 0 2px $neutral-30;
    }
  
    &-icon path {
      fill: $neutral-60;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
  &__container {
    margin-top: spacing(1);
    max-height: spacing(50);
    padding: spacing(2) spacing(2) 0;
    width: spacing(30);
  }

  &__option {
    max-width: calc(100% + #{spacing(4)});
  }
  
  &__list {
    margin: spacing(2) spacing(2) * -1 0;
    padding: 0;
    width: calc(100% + #{spacing(4)});
  
    &:not(&--fixed) {
      margin-top: spacing(2);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
