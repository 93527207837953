@import '~yordex-ui-kit/yordexUiKit';
@import '../../../scss/form';

.phone-number-form {
  @include public-form();

  &__error-message {
    margin-bottom: spacing(3);
  }

  &__container {
    width: 100%;
  }
}
