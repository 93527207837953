@import '~yordex-ui-kit/yordexUiKit';

@mixin public-form {
  width: 100%;

  &__input {
    margin-bottom: spacing(3);
    width: 100%;
  }

  &__button {
    width: 100%;
  }

  &__error-message {
    margin-bottom: spacing(3);
  }
}

%link {
  @include display-as($font-type-t300);
    
  color: $blue;
}

