@import '~yordex-ui-kit/yordexUiKit';

.delegates-modal {
  @include breakpoint(xs) {
    min-width: spacing(56);
  }

  &__options {
    @include scrollbar();

    max-height: calc(100vh - #{spacing(26)});
    overflow: auto;

    @include breakpoint(xs) {
      max-height: spacing(24);
    }
  }

  &__user-option {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &__user-email {
    margin: 0;
    margin-right: spacing(0.5);

    @include breakpoint(xs) {
      margin-right: spacing(1);
    }
  }
}
