@import '~yordex-ui-kit/yordexUiKit';

body {
  font-family: InterV, Sans-Serif;
  margin: 0;
}

:global(#root) {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.text-link {
  color: $blue;
}

h1 {
  @include display-as($font-type-t900);
}

h2 {
  @include display-as($font-type-t800);
}

h3 {
  @include display-as($font-type-t600);
}
