@import '~yordex-ui-kit/yordexUiKit';
@import '../../../scss/form';

.login-sms {
  @include public-form();

  &__error-message {
    margin-bottom: spacing(3);
  }
}
