@import '~yordex-ui-kit/yordexUiKit';

.login-otp {
  &__request-token-link {
    height: spacing(3);
    padding: 0;
  }

  &__request-token-info {
    padding-left: spacing(0.5);
    padding-right: spacing(0.5);
  }
}
