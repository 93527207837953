@import '~yordex-ui-kit/yordexUiKit';

.login {
  &__register-password-link {
    @include display-as($font-type-t300);
    color: $blue;
    display: block;
    margin-left: 0;

    @include breakpoint(491) {
      display: inline;
      margin-left: spacing(0.5);
    }
  }

  &__forgot-password-link {
    @include display-as($font-type-t300);
    color: $blue;

    margin-top: spacing(3);
  }
}
