@import '~yordex-ui-kit/yordexUiKit';

.social-login-buttons {
  display: flex;
  flex-direction: column;
  max-width: spacing(48);
  width: 80%;

  &__divider {
    @include display-as($font-type-t300);
    color: $neutral-80;
    display: flex;
    margin: spacing(2) 0;
    position: relative;

    @include breakpoint(xs) {
      margin: spacing(4) 0;
    }

    &::before,
    &::after {
      border-bottom: 2px solid $neutral-30;
      content: '';
      flex: 1;
      position: relative;
      top: -#{spacing()};
    }

    &::before {
      margin-right: spacing(1);
    }

    &::after {
      margin-left: spacing(1);
    }
  }

  &__button {
    flex: 1;
    position: relative;

    &:enabled {
      background-color: $white;
      border-color: $neutral-40;
      color: $neutral-80;
    }

    &:disabled {
      border-color: $neutral-40;
    }
  }

  &__google-container {
    display: flex;
    width: 100%;
  }

  &__microsoft-container {
    margin-top: spacing(2);
    width: 100%;
    
    @include breakpoint(xs) {
      margin-top: spacing(3);
    }

    // MicrosoftLogin creates an empty div with onClick handler
    // there is no way to pass a class to it
    > div {
      display: flex;
      width: 100%;
    }
  }

  &__logo {
    left: spacing(2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
